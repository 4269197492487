import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../../composants/image/Panellum"

import { obtenirPage } from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "observatoireRoqueMuchachos360EN" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function RoqueMuchachos360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const composantImageInteractive = (
    <Pannellum
      urlImageSource="/ressources/360/roqueMuchachos.jpg"
      altitudeParDefaut={0}
    />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          This 360° plunges you into the heart of a sunset 2,400 meters above
          the sea, at the top of the Roque de los Muchachos Observatory on the
          island of La Palma in Canary Islands. This observatory is one of the
          largest in the northern hemisphere with the observatory of Mauna Kea
          located in Hawaii.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          20 frames panoramic, Bushman Gobi panoramic head, Canon EOS 6D
          Astrodon modded, Samyang 24mm F1.4 set to F4, ISO 400. Individual
          exposures of 0.25 seconds.
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
